export const home = {
  title: { sv: "Hem", en: "Home" },
  path: "/",
  header: true,
};

export const projects = {
  title: { sv: "Projekt", en: "Projects" },
  path: "/projects",
  header: true,
};
