const Size = {
  smallXX: '0.4rem',
  smallX: '0.6rem',
  small: '0.8rem',
  regular: '1rem',
  large: '1.2rem',
  largeX: '1.5rem',
  largeXX: '2rem',
  largeXXX: '2.5rem',
  largeXXXX: '3rem',
  largeXXXXX: '4rem',
};

const Weight = {
  light: 300,
  regular: 400,
  semiBold: 600,
  bold: 700,
};

const Family = {
  head: "'Vogue', serif;",
  text: "'Roboto', sans-serif",
  mono: "'Roboto Mono', monospace",
  hand: "'Sacramento', cursive",
};

const Fonts = {
  Size,
  Weight,
  Family,
};

export default Fonts;
