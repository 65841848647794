import { useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollRestoration: FC = () => {
  const { pathname } = useLocation();
  useEffect(() => window.scrollTo(0, 0), [pathname]);
  return null;
};

export default ScrollRestoration;
